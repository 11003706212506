<template>
    <div class="ow">
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-lg-12">
                    <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                        <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> Transaction History</h2>
                    </div>
                </div>            
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-2 col-6 form-group">
                    <select @change="page = 1;getNotification()" v-model="type" id="" class="form-control form-control-sm">
                        <option value="">All</option>
                        <option value="TOPUP">Top Up</option>
                        <option value="BUY">Buy Course</option>
                        <option value="POINT">Point</option>
                    </select>
                </div>
                <div class="col-lg-2 col-6 form-group">
                    <input @change="page = 1;getNotification()" v-model="date" type="date" class="form-control form-control-sm">
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-12" v-if="!isLoad">
                    <div v-if="notification.data && notification.data.length" class="row">
                        <div class="col-12">
                            <div class="rounded-lg card-body px-lg-0 py-3 shadow-sm mt-2" v-for="(item, index) in notification.data" :key="index">
                                <div class="row overflow-auto h-auto justify-content-around px-lg-4 px-2">
                                    <div class="col-lg-10">
                                        <div class="row ">
                                            <div class="col">
                                                <i v-if="item.status == 'SUCCESS'" class="fas fa-check font-xsss text-success mr-2"></i>
                                                <i v-if="item.status == 'PENDING'" class="fas fa-clock font-xsss text-warning mr-2"></i>
                                                <i v-if="item.status == 'FAILED'" class="fas fa-times font-xsss text-danger mr-2"></i>
                                                <span class="text-current fw-600"><span class="text-grey-700 fw-700 mr-1">Transaction : </span> {{ item.transaction_code }} </span>
                                                <br>
                                                <span class="fw-500" :class="{'text-success':item.status == 'SUCCESS','text-warning':item.status == 'PENDING','text-danger':item.status == 'FAILED',  }"><span class="text-grey-600 fw-600 mr-1">Status : </span> {{ item.status }} </span>
                                                <p class="text-current fw-500"><span class="text-grey-600 fw-600 mr-1">Type Code : </span> {{ item.transaction_type_code }} </p>
                                                <span class="font-xsss fw-600 d-block mr-1 text-success">Rp. {{item.amount | numFormat}}</span>
                                            </div>  
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-2 text-right">
                                        <small class="fw-500 text-grey-500">{{item.created_at | datetime}}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mb-4">
                            <ul class="pagination pagination-center justify-content-center d-flex pt-5 w-100">
                                <li v-for="(link, index) in notification.links" :key="index" class="page-item m-1">
                                    <button v-if="link.label === '&laquo; Previous'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="prevData()"><i class="ti-angle-left"></i></button>
                                    <button v-else-if="link.label === 'Next &raquo;'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="nextData()"><i class="ti-angle-right"></i></button>
                                    <button v-else v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" @click="changeData(link.label)">{{link.label}}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-12">
                            <div class="rounded-lg card-body text-center shadow-sm mt-2 py-lg-5 py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-12">
                    <div class="rounded-lg card-body shadow-sm mt-2">
                        <div class="row overflow-auto h-auto justify-content-around px-4">            
                            <div class="col-12 text-center py-3">
                                <div class="my-3">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data(){
        return{
            notification: [],
            isLoad: true,
            type: '',
            date: '',
            page: 1
        }
    },
    mounted(){
        this.getNotification()
    },
    filters:{
        datetime: function (date) {
            return moment(date).calendar();
        }
    },
    methods:{
        async getNotification(){
            await axios.get(`${process.env.VUE_APP_URL_API}/transaction-wallet?type=${this.type}&date=${this.date}&page=${parseInt(this.page)}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.notification = res.data.data
                this.isLoad = false
            }).catch((err) => {
                console.log(err)
            })
        },
        changeData(page) {
            this.page = parseInt(page)
            this.getNotification()
        },
        prevData() {
            if (this.page >= 1) {
                this.page -= 1
                this.getNotification()
            }
        },
        nextData() {
            if (this.notification.last_page > this.page) {
                this.page += 1
                this.getNotification()
            }
        },
    }
}
</script>
<style scoped>
    .card-body{
        background-color: #FFFFFF;
    }
    button{
        background-color: transparent;
        border: none;
    }
    button:focus {
        outline: 1px dotted;
        outline: 1px auto white;
    }
    p{
        line-height: 1.5;
    }
</style>